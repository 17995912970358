<!--
  - Copyright (C)  E-Synaps SAS - 2021 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-card flat>
    <v-navigation-drawer width="100%">
      <v-list-item inactive two-line>
        <v-list-item-avatar tile>
          <font-awesome-icon fixed-width size="2x" :icon="['fad', 'hospital-user']" class="primary--text" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">{{ patient.full_name }}</v-list-item-title>
          <v-list-item-subtitle class="secondary--text text--darken-1 font-weight-light font-alt">
            Fiche Patient
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider />

      <v-list nav>
        <v-list-item link :to="{name: 'my-contacts'}" exact>
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'chevron-left']" class="grey--text text--darken-1" />
          </v-list-item-icon>
          <v-list-item-title>Retour à la liste</v-list-item-title>
        </v-list-item>
        <v-list-item-title class="my-3 px-2 text-uppercase font-weight-light font-alt text-body-2">Informations</v-list-item-title>
        <v-list-item link :to="{name: 'patient-profile'}" exact>
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'user']" class="grey--text text--darken-1" />
          </v-list-item-icon>
          <v-list-item-title>Informations générales</v-list-item-title>
        </v-list-item>
        <v-divider />
        <v-list-item-title class="my-3 px-2 text-uppercase font-weight-light font-alt text-body-2">Actions</v-list-item-title>
        <v-list-item link exact>
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'user-plus']" class="grey--text text--darken-1" />
          </v-list-item-icon>
          <v-list-item-title>Ajouter à mes contacts</v-list-item-title>
        </v-list-item>
        <v-list-item class="mt-2" @click="messengerTo">
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'user-md-chat']" class="grey--text text--darken-1" />
          </v-list-item-icon>
          <v-list-item-title>Envoyer un message</v-list-item-title>
          <v-list-item-action v-if="loading.messenger">
            <font-awesome-icon fixed-width size="lg" :icon="['fad', 'spinner-third']" spin
                               class="primary--text"
            />
          </v-list-item-action>
        </v-list-item>
        <v-list-item link exact class="mt-2" @click="openPatientEdit">
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'users-medical']" class="grey--text text--darken-1" />
          </v-list-item-icon>
          <v-list-item-title>Inviter dans un groupe</v-list-item-title>
        </v-list-item>
        <v-list-item link exact class="mt-2" @click="openPatientEdit">
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'files-medical']" class="grey--text text--darken-1" />
          </v-list-item-icon>
          <v-list-item-title>Partager un document</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
  import * as Conversations from '@/modules/messenger/api/conversations'
  import {Conversation} from '@/modules/messenger/models'

  export default {
    name: 'PatientSideBar',
    props: {
      patient: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        loading: {
          messenger: false,
          isMyContact: true
        },
        myProfile: false
      }
    },
    methods: {
      openPatientEdit() {
        this.$root.$emit('modal:open', {
          modal: () => import('@/modules/patients/components/PatientForm'),
          props: {
            patientUid: this.patient.uid
          }
        })
      },
      openTeamCreate() {
        this.$root.$emit('modal:open', {
          modal: () => import('@/modules/teams/components/TeamForm'),
          props: {
            patientUid: this.patient.uid
          }
        })
      },
      openReferral() {
        this.$root.$emit('modal:open', {
          modal: () => import('@/modules/features/referral/views/ReferralForm'),
          props: {
            patientUid: this.patient.uid
          }
        })
      },
      async messengerTo() {
        this.loading.messenger = true
        let {data} = await Conversations.getByParticipant(this.patient.uid)
        Conversation.save(data)
        this.loading.messenger = false
        this.$store.commit('messenger/layout/openChatWindow', data.uid)
      }
    }
  }
</script>
