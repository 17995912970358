<template>
  <v-container v-if="patient" fluid>
    <v-row>
      <v-col cols="12" xl="3" lg="4" :class="$vuetify.breakpoint.mdAndDown ? 'pa-0' : ''">
        <patient-side-bar-professional v-if="$auth.granted('role_professionals')" :patient="patient" />
        <patient-side-bar-patients v-else :patient="patient" />
      </v-col>
      <v-col cols="12" xl="6" lg="8" :class="$vuetify.breakpoint.mdAndDown ? 'px-1' : ''">
        <patient-header-content :patient="patient" />
        <vue-page-transition name="fade-in-down">
          <router-view :key="$route.fullPath" :patient="patient" />
        </vue-page-transition>
      </v-col>
    </v-row>
  </v-container>
  <page-loader v-else />
</template>

<script>
  import PatientSideBarProfessional from '@/modules/patients/components/professionals/PatientSideBar'
  import PatientSideBarPatients from '@/modules/patients/components/patients/PatientSideBar'
  import PatientHeaderContent from '@/modules/patients/components/PatientHeaderContent'
  import PageLoader from '@/modules/core/layout/PageLoader'
  import {Patient} from '@/models'

  export default {
    name: 'PatientSection',
    components: {PatientSideBarProfessional,PatientSideBarPatients, PageLoader, PatientHeaderContent},
    computed: {
      patient() {
        return Patient.get(this.$route.params.uid)
      }
    },
    created() {
      Patient.fetchOne(this.$route.params.uid)
    }
  }
</script>
