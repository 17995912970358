<!--
  - Copyright (C)  E-Synaps SAS - 2021 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-navigation-drawer v-model="localDrawer" :value="menuDrawerMobile"
                       :width="$vuetify.breakpoint.mdAndDown ? '90%' : '100%'"
                       :class="$vuetify.breakpoint.mdAndDown ? 'navigation-mobile-container' : ''"
                       :height="$vuetify.breakpoint.lgAndUp ? 'auto' : 'calc(100% - 112px)'"
                       :permanent="$vuetify.breakpoint.lgAndUp" :fixed="$vuetify.breakpoint.mdAndDown"
  >
    <v-card flat class="h-100">
      <v-list-item inactive two-line>
        <v-list-item-avatar tile>
          <font-awesome-icon fixed-width size="2x" :icon="['fad', 'hospital-user']" class="primary--text" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">{{ patient.full_name }}</v-list-item-title>
          <v-list-item-subtitle class="secondary--text text--darken-1 font-weight-light font-alt">
            Fiche Patient
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider />

      <v-list nav>
        <v-list-item link :to="{name: 'my-patients'}" exact>
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'chevron-left']"
                               class="grey--text text--darken-1"
            />
          </v-list-item-icon>
          <v-list-item-title>Retour à la liste</v-list-item-title>
        </v-list-item>
        <v-list-item-title class="my-3 px-2 text-uppercase font-weight-light font-alt text-body-2">
          Informations
        </v-list-item-title>
        <v-list-item link :to="{name: 'patient-profile'}" exact>
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'user']" class="grey--text text--darken-1" />
          </v-list-item-icon>
          <v-list-item-title>Informations générales</v-list-item-title>
        </v-list-item>
        <!--        <v-list-item link href="https://pro.aptelecare.com/login/" target="_blank">-->
        <!--          <v-list-item-icon>-->
        <!--            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'files-medical']"-->
        <!--                               class="grey&#45;&#45;text text&#45;&#45;darken-1"-->
        <!--            />-->
        <!--          </v-list-item-icon>-->
        <!--          <v-list-item-title>-->
        <!--            Télésuivi du patient <span class="font-italic op-80">via</span>-->
        <!--            <img alt="ApTelecare" src="@/assets/img/applogo/aptelecare.jpg"-->
        <!--                 style="height: 18px;margin-left: 8px;vertical-align: middle;"-->
        <!--            >-->
        <!--          </v-list-item-title>-->
        <!--        </v-list-item>-->
        <!--        <v-list-item link :to="{name: 'patient-documents'}" exact>-->
        <!--          <v-list-item-icon>-->
        <!--            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'file-signature']"-->
        <!--                               class="grey&#45;&#45;text text&#45;&#45;darken-1"-->
        <!--            />-->
        <!--          </v-list-item-icon>-->
        <!--          <v-list-item-title>Documents</v-list-item-title>-->
        <!--        </v-list-item>-->
        <v-divider />
        <v-list-item-title class="my-3 px-2 text-uppercase font-weight-light font-alt text-body-2">
          Actions
        </v-list-item-title>
        <v-list-item link exact @click="openTeamCreate">
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'users-medical']"
                               class="grey--text text--darken-1"
            />
          </v-list-item-icon>
          <v-list-item-title>Créer un groupe autour de ce patient</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="patient.my_patient" class="mt-2" @click="openReferral">
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'user-md-chat']"
                               class="grey--text text--darken-1"
            />
          </v-list-item-icon>
          <v-list-item-title>Adresser ce patient à un professionnel</v-list-item-title>
        </v-list-item>
        <v-list-item link exact class="mt-2" @click="openPatientEdit">
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'user-edit']" class="grey--text text--darken-1" />
          </v-list-item-icon>
          <v-list-item-title>Mettre à jour les informations du patient</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="patient.my_patient" link exact :disabled="loading.removePatient" @click="removePatient">
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'user-times']" class="grey--text text--darken-1" />
          </v-list-item-icon>
          <v-list-item-title>Retirer ce patient de ma liste</v-list-item-title>
          <v-list-item-action v-if="loading.removePatient">
            <font-awesome-icon fixed-width size="lg" :icon="['fad', 'spinner-third']" spin
                               class="primary--text"
            />
          </v-list-item-action>
        </v-list-item>
        <v-list-item v-else link exact :disabled="loading.addPatient" @click="addPatient">
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'user-plus']" class="grey--text text--darken-1" />
          </v-list-item-icon>
          <v-list-item-title>Ajouter ce patient à ma liste</v-list-item-title>
          <v-list-item-action v-if="loading.addPatient">
            <font-awesome-icon fixed-width size="lg" :icon="['fad', 'spinner-third']" spin
                               class="primary--text"
            />
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
  import { mapState } from 'vuex'
  import { add, remove } from '@/modules/patients/api'

  export default {
    name: 'PatientSideBar',
    props: {
      patient: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        loading: {
          removePatient: false,
          addPatient: false
        }
      }
    },
    computed: {
      ...mapState('layout', ['menuDrawerMobile']),
      localDrawer: {
        get() {
          return this.$store.state.layout.menuDrawerMobile
        },
        set(value) {
          this.$store.commit('layout/setDrawerMobile', value)
        }
      }
    },
    methods: {
      openPatientEdit() {
        this.$root.$emit('modal:open', {
          modal: () => import('@/modules/patients/components/PatientForm'),
          props: {
            patientUid: this.patient.uid
          }
        })
      },
      openTeamCreate() {
        this.$root.$emit('modal:open', {
          modal: () => import('@/modules/teams/components/TeamForm'),
          props: {
            patientUid: this.patient.uid
          }
        })
      },
      openReferral() {
        this.$root.$emit('modal:open', {
          modal: () => import('@/modules/features/referral/views/ReferralForm'),
          props: {
            patientUid: this.patient.uid
          }
        })
      },
      async removePatient() {
        if (await this.$confirm('Êtes vous sur de vouloir retirer ' + this.patient.full_name + ' de vos patients ?')) {
          try {
            this.loading.removePatient = true

            await remove(this.patient.uid, this.$auth.user.sub)

            this.patient.$mutate(patient => {
              patient.my_patient = false
            })

            this.$root.$emit('snackbar:open', {
              snackbar: () => import('@/modules/core/layout/snackbars/Snackbar'),
              type: 'success',
              title: 'Patient retiré',
              subtitle: 'Le patient a été retiré avec succès.'
            })
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e)
            this.$root.$emit('snackbar:open', {
              snackbar: () => import('@/modules/core/layout/snackbars/Snackbar'),
              type: 'error',
              title: 'Une erreur est survenue',
              subtitle: 'Le patient n\'a pas pu être retiré de votre liste.'
            })
          } finally {
            this.loading.removePatient = false
          }
        }
      },
      async addPatient() {
        try {
          this.loading.addPatient = true

          await add(this.patient.uid, this.$auth.user.sub)

          this.patient.$mutate(patient => {
            patient.my_patient = true
          })

          this.$root.$emit('snackbar:open', {
            snackbar: () => import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'success',
            title: 'Patient ajouté',
            subtitle: 'Le patient a été ajouté avec succès.'
          })
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e)
          this.$root.$emit('snackbar:open', {
            snackbar: () => import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue',
            subtitle: 'Le patient n\'a pas pu être ajouté dans votre liste.'
          })
        } finally {
          this.loading.addPatient = false
        }
      }
    }
  }
</script>
