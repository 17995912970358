<template>
  <v-card class="elevation-x mb-7">
    <v-list-item inactive two-line align="start">
      <v-list-item-avatar :width="$vuetify.breakpoint.mdAndDown ? '50px' : '80px'" :height="$vuetify.breakpoint.mdAndDown ? '50px' : '80px'" class="mt-0">
        <user-avatar :size="$vuetify.breakpoint.mdAndDown ? '50px' : '80px'" :user="patient" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="text-md-h4 text-h6 font-alt font-weight-thin">{{ patient.full_name }}</v-list-item-title>
        <v-list-item-subtitle class="secondary--text text--darken-1 font-weight-thin font-alt text-md-h6 text-subtitle-2">
          <base-date :date="patient.birth_date" not-relative format="LL" />
        </v-list-item-subtitle>
        <div class="actions-list">
          <v-chip small class="my-2 pl-0 mr-2 font-alt" color="primary" :disabled="!patient.phone" @click.stop="call(patient.phone)">
            <font-awesome-layers class="fa-lg mx-1">
              <font-awesome-icon icon="circle" class="primary--text text--lighten-2" />
              <font-awesome-icon :icon="['fad', 'phone-alt']" transform="shrink-7" class="white--text" />
            </font-awesome-layers>
            Appeler
          </v-chip>
          <v-chip small class="my-2 pl-0 mr-2 font-alt" color="primary" :disabled="!patient.email" @click.stop="sendmail(patient.email)">
            <font-awesome-layers class="fa-lg mx-1">
              <font-awesome-icon icon="circle" class="primary--text text--lighten-2" />
              <font-awesome-icon :icon="['fad', 'at']" transform="shrink-7" class="white--text" />
            </font-awesome-layers>
            E-mail
          </v-chip>
        </div>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
  </v-card>
</template>

<script>
  import BaseDate from '@/modules/core/components/BaseDate'
  import contactMethods from '@/mixins/contactMethods'
  import UserAvatar from '@/modules/core/layout/UserAvatar'
  export default {
    name: 'PatientHeaderContent',
    components: {UserAvatar, BaseDate},
    mixins: [contactMethods],
    props: {
      patient: {
        type: Object,
        required: false
      }
    }
  }
</script>
